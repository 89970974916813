import { Component } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import style from './InfoBlock.scss';
import { Colors } from '~/utils/theme/colors';
import { mdiLightbulbOnOutline } from '~/utils/iconPaths';

const rootClass = 'czt-info-block';

@Component({ style })
export default class InfoBlock extends VueComponent<{}> {
  public render() {
    return (
      <div class={rootClass}>
        <v-icon x-large color={Colors.TEXT} class='align-self-start'>
          {mdiLightbulbOnOutline}
        </v-icon>
        <div class={`${rootClass}__content`}>{this.$slots.default}</div>
      </div>
    );
  }
}
